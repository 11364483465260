<template>
    <form @submit="save">
        <div class="container">
            <Heading :heading="$t('registration.heading')" />
            <br />
            <fieldset>
                <div>
                    <div class="form-group row">
                        <label for="firstName" class="col-sm-2 col-form-label">{{ $t('registration.firstName') }}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="firstName" required :placeholder="$t('registration.firstName')" v-model="fields.firstName" />
                        </div>
                    </div>
                    <br />
                    <div class="form-group row">
                        <label for="lastName" class="col-sm-2 col-form-label">{{ $t('registration.lastName') }}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="lastName" required :placeholder="$t('registration.lastName')" v-model="fields.lastName" />
                        </div>
                    </div>
                    <br />
                    <div class="form-group row">
                        <label for="email" class="col-sm-2 col-form-label">{{ $t('registration.email') }}</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" id="email" required :placeholder="$t('registration.email')" v-model="fields.email" />
                        </div>
                    </div>
                    <br />
                    <div class="form-group row">
                        <label for="gender" class="col-sm-2 col-form-label">{{ $t('registration.gender') }}</label>
                        <div class="col-sm-10">
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" required name="gender" id="genderMan" :value="1" v-model="fields.gender"> {{ $t('registration.male') }}
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" required name="gender" id="genderWoman" :value="2" v-model="fields.gender"> {{ $t('registration.female') }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                <hgroup>
                    <h4>{{ $t('registration.passwordSection') }}</h4>
                </hgroup>
                <div>
                    <div class="form-group row">
                        <label for="password" class="col-sm-2 col-form-label">{{ $t('registration.password') }}</label>
                        <div class="col-sm-10">
                            <input type="password" class="form-control" id="password" required :placeholder="$t('registration.password')" v-model="fields.password" :pattern="this.passwordPattern" />
                        </div>
                    </div>
                    <br />
                    <div class="form-group row">
                        <label for="confirmPassword" class="col-sm-2 col-form-label">{{ $t('registration.confirmPassword') }}</label>
                        <div class="col-sm-10">
                            <input type="password" class="form-control" id="confirmPassword" required :placeholder="$t('registration.confirmPassword')" v-model="fields.confirmPassword" :pattern="this.passwordPattern" />
                        </div>
                    </div>
                    <br />
                </div>
                <div class="form-group row">
                    <div class="col-sm-2">
                        <button id="btnSubmit" class="btn btn-success text-center">{{ $t('registration.submit') }}</button> &nbsp; {{ $t('registration.or') }}
                        <router-link to="/login">{{ $t('registration.login') }}</router-link>
                    </div>
                    <div class="col-sm-10">
                        <div v-if="this.loading">{{ $t('registration.loading') }}</div>
                        <div id="registrationStatusBadge" v-else v-show="status" :class="{ 'alert-success': this.statusClass == 'success', 'alert-danger': this.statusClass == 'danger' }" class="alert" role="alert">
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only"></span> {{ status }}
                        </div>
                    </div>
                </div>
                <br />
            </fieldset>
        </div>
    </form>
</template>

<script lang="js">
import { fetchWrapper } from '@/helpers';
import Heading from '../components/Heading.vue';

export default {
    data() {
        return {
            fields: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                gender: 1
            },
            loading: false,
            status: '',
            statusClass: '',
            passwordPattern: ".{8,}"
        };
    },
    mounted() {
        // let myScript = document.createElement('script');
        // myScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6Lce4CgqAAAAALXUa5i4ex9VJMTNEM7sBpCPYEar');
        // document.body.appendChild(myScript);
    },
    methods: {
        async save(event) {
            try {
                event.preventDefault();
                // grecaptcha.ready(function () {
                //     grecaptcha.execute('6Lce4CgqAAAAALXUa5i4ex9VJMTNEM7sBpCPYEar', { action: 'submit' }).then(function (token) {
                //         debugger;
                //     });
                // });
                this.loading = true;
                if (!new RegExp(this.passwordPattern).test(this.password) || !new RegExp(this.passwordPattern).test(this.confirmPassword)) {
                    this.loading = false;
                    this.status = this.$t('registration.weakPassword');
                    this.statusClass = 'danger';
                    return;
                }
                if (this.fields.confirmPassword !== this.fields.password) {
                    this.loading = false;
                    this.status = this.$t('registration.passwordMismatch');
                    this.statusClass = 'danger';
                    return;
                }
                let response = await fetchWrapper.post('User/Add', this.fields);
                this.loading = false;
                if (response && response.id != 0) {
                    if (response.status == 204) {
                        this.status = this.$t('registration.userExists');
                        this.statusClass = 'danger';
                        return;
                    }
                    this.status = this.$t('registration.success');
                    this.statusClass = 'success';
                } else {
                    this.status = this.$t('registration.error');
                    this.statusClass = 'danger';
                }
            } catch (error) {
                this.loading = false;
                this.status = this.$t('registration.error');
                this.statusClass = 'danger';
                console.log(error);
            }
        }
    },
    components: { Heading }
}
</script>

<style scoped></style>