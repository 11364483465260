<template>
    <div class="social-login">
        <button class="btn btn-primary me-2" @click="loginWithGoogle">
            {{ $t('social.googleLogin') }}
        </button>
        <button class="btn btn-info" @click="loginWithFacebook">
            {{ $t('social.facebookLogin') }}
        </button>
        <div v-if="error" class="alert alert-danger mt-2">
            {{ error }}
        </div>
    </div>
</template>

<script>
import { auth } from '@/firebase/config';
import { 
    FacebookAuthProvider, 
    GoogleAuthProvider,
    signInWithPopup 
} from 'firebase/auth';
import { useAuthStore } from '@/stores';

export default {
    data() {
        return {
            error: null
        }
    },
    methods: {
        async handleSocialLogin(provider) {
            try {
                const result = await signInWithPopup(auth, provider);
                const credential = await result.user.getIdToken();
                
                const authStore = useAuthStore();
                await authStore.loginWithSocial(credential);
            } catch (error) {
                this.error = error.message;
                console.error('Social login error:', error);
            }
        },
        loginWithGoogle() {
            this.handleSocialLogin(new GoogleAuthProvider());
        },
        loginWithFacebook() {
            this.handleSocialLogin(new FacebookAuthProvider());
        }
    }
}
</script>
