<template>
    <div class="blog-container py-5">
        <div class="container">
            <div class="row">
            <div class="col-12 text-center">
                <h1 class="display-4">{{ $t('blog.title') }}</h1>
            </div>
            </div>

            <!-- Loading State -->
            <div class="row min-vh-50 align-items-center" v-if="loading">
                <div class="col-12 text-center">
                    <div class="spinner-border spinner-border-lg text-primary" role="status">
                        <span class="visually-hidden">{{ $t('blog.loading') }}</span>
                    </div>
                </div>
            </div>

            <!-- Error State -->
            <div class="row" v-else-if="error">
                <div class="col-12">
                    <div class="alert alert-danger shadow-sm" role="alert">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>
                        {{ error }}
                    </div>
                </div>
            </div>

            <!-- Blog Posts Grid -->
            <div class="row g-4" v-else>
                <div class="col-md-6 col-lg-4" v-for="post in posts" :key="post.id">
                    <blogPost :post="post" class="card h-100 shadow-sm transition-hover" />
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="js">
import blogPost from '@/components/BlogpostCard.vue';
import blogService from '@/services/blogService';

export default {
    data() {
        return {
            posts: [],
            loading: false,
            error: ''
        };
    },
    async created() {
        await this.fetchPosts();
    },
    components: {
        blogPost
    },
    methods: {
        async fetchPosts() {
            this.loading = true;
            try {
                this.posts = await blogService.getAllBlogPosts();
            } catch (error) {
                this.error = error.message;
            } finally {
                this.loading = false;
            }
        },
        async refreshPosts() {
            this.error = '';
            await this.fetchPosts();
        }
    }
}
</script>
<style scoped>
.blog-container {
    background-color: #f8f9fa;
    min-height: 100vh;
}

.underline {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #007bff, #6610f2);
    border-radius: 2px;
}

.spinner-border-lg {
    width: 3rem;
    height: 3rem;
}

.transition-hover {
    transition: all 0.3s ease;
}

.transition-hover:hover {
    transform: translateY(-5px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.min-vh-50 {
    min-height: 50vh;
}
</style>