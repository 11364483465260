<template>
    <div class="container">
        <heading :heading="$t('login.title')" />
        <br />
        <form @submit="submit">
            <fieldset>
                <div>
                    <div class="form-group row">
                        <label for="email" class="col-sm-2 col-form-label">{{ $t('login.email') }}</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" id="email" required :placeholder="$t('login.email')" v-model="fields.email" />
                        </div>
                    </div>
                    <br />
                    <div class="form-group row">
                        <label for="password" class="col-sm-2 col-form-label">{{ $t('login.password') }}</label>
                        <div class="col-sm-10">
                            <input type="password" class="form-control" id="password" required :placeholder="$t('login.password')" v-model="fields.password" />
                        </div>
                    </div>
                    <br />
                </div>
                <div class="form-group row">
                    <div class="col-sm-2">
                        <button id="btnSubmit" type="submit" class="btn btn-success text-center">{{ $t('login.submit') }}</button> &nbsp; {{ $t('login.or') }}
                        <router-link to="/register">{{ $t('login.register') }}</router-link> &nbsp;
                    </div>
                    <div class="col-sm-10">
                        <router-link to="/reset-password-request">{{ $t('login.resetPassword') }}</router-link>
                        <div v-if="loading">{{ $t('login.loading') }}</div>
                        <div v-else v-show="status" :class="{ 'alert-success': statusClass === 'success', 'alert-danger': statusClass === 'danger' }" class="alert" role="alert">
                            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                            <span class="sr-only"></span> {{ status }}
                        </div>
                    </div>
                </div>
                <br />
            </fieldset>
        </form>
        <SocialLogin />
    </div>
</template>

<script lang="js">
    import { useAuthStore } from '@/stores';
    import heading from '../components/Heading.vue';
    import SocialLogin from '../components/SocialLogin.vue';

    export default {
        data() {
            return {
                fields: {
                    email: '',
                    password: ''
                },
                loading: false,
                status: '',
                statusClass: ''
            };
        },
        components: {
            heading, SocialLogin
        },
        methods: {
            async submit(event) {
                try {
                    event.preventDefault();

                    this.loading = true;

                    const authStore = useAuthStore();

                    const result = await authStore.login(this.fields.email, this.fields.password)
                        .catch(error => console.log(error));

                    this.loading = false;

                    if (!result) {
                        this.status = this.$t('login.failed');
                        this.statusClass = "danger";
                    }
                }
                catch (error) {
                    this.loading = false;
                    console.log(error);
                    this.status = this.$t('login.userNotFound');
                    this.statusClass = 'danger';
                }
            }
        }
    }
</script>

<style scoped></style>