<template>
    <div class="container-fluid py-5 bg-light">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <article class="bg-white shadow-sm rounded p-4 p-md-5">
                    <header class="mb-4">
                        <h1 class="display-4 fw-bold mb-3" v-html="blogpost.title"></h1>
                        <div class="d-flex align-items-center">
                            <i class="bi bi-calendar-event me-2"></i>
                            <span class="text-muted">
                                {{ moment(blogpost.datePublished).format('MMMM Do, YYYY') }}
                            </span>
                        </div>
                    </header>
                    <hr class="my-4">
                    <section class="blog-content">
                        <p class="lead" v-html="blogpost.content"></p>
                    </section>
                </article>
            </div>
        </div>
    </div>
</template>
<script lang="js">
import moment from 'moment';
import blogService from '@/services/blogService';
export default {
    data() {
        return {
            moment: moment,
            blogpost: {
                title: '',
                content: '',
                datePublished: null
            }
        };
    },
    mounted() {
        this.loadBlogpost();
    },
    methods: {
        async loadBlogpost() {
            const blogpostId = this.$route.params.id;
            try {
                this.blogpost =  await blogService.getBlogPostById(Number(blogpostId));
            } catch (error) {
                console.error('Error fetching blogpost:', error);
            }
        }
    }
};
</script>

<style scoped>
.container {
    max-width: 80%;
}

.display-4 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.text-muted {
    font-size: 1em;
    color: #888;
    margin-bottom: 20px;
}

.lead {
    font-size: 1.2em;
    line-height: 1.6;
}
</style>