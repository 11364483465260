
<template>
    <hgroup>
        <h2 class="text-center">{{ $t('admin.title') }}</h2>
    </hgroup>
    <ul class="nav nav-tabs" id="tabs" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="dates-tab" data-bs-toggle="tab" data-bs-target="#dates" type="button" role="tab" aria-controls="dates" aria-selected="true">{{ $t('admin.dates') }}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="users-tab" data-bs-toggle="tab" data-bs-target="#users" type="button" role="tab" aria-controls="users" aria-selected="false">{{ $t('admin.users') }}</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="dates" role="tabpanel" aria-labelledby="dates-tab">
            <div class="container-fluid">
                <hgroup>
                    <h3>{{ $t('admin.dates') }}</h3>
                </hgroup>
                <div>
                    <button @click="saveDates" class="btn btn-success">{{ $t('admin.save') }}</button>
                    <button @click="deleteDates" class="btn btn-danger">{{ $t('admin.deleteAll') }}</button>
                    <button @click="resetDates" class="btn btn-danger">{{ $t('admin.resetAll') }}</button>
                    <button @click="deleteSpots" class="btn btn-danger">{{ $t('admin.deleteSpots') }}</button>
                    <button @click="deleteOrders" class="btn btn-danger">{{ $t('admin.deleteOrders') }}</button>
                </div>
                <table v-if="this.dates" class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>{{ $t('admin.id') }}</th>
                            <th>{{ $t('admin.title') }}</th>
                            <th>{{ $t('admin.startsOn') }}</th>
                            <th>{{ $t('admin.status') }}</th>
                            <th>{{ $t('admin.minMen') }}</th>
                            <th>{{ $t('admin.men') }}</th>
                            <th>{{ $t('admin.minWomen') }}</th>
                            <th>{{ $t('admin.women') }}</th>
                            <th>{{ $t('admin.minAges') }}</th>
                            <th>{{ $t('admin.maxAges') }}</th>
                            <th>{{ $t('admin.price') }}</th>
                            <th>{{ $t('admin.link') }}</th>
                            <th>{{ $t('admin.action') }}</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td> <input @input="event => newDate.title = event.target.value" type="text" :v-model="newDate.title" class="form-control" :value="newDate.title" /></td>
                            <td>
                                <VueDatePicker v-model="newDate.startsOn" />
                            </td>
                            <td>
                                <select v-model="newDate.status" class="form-control" :value="newDate.status">
                                    <option v-for="(status, statusKey) in this.statuses" :value="status.key" :key="statusKey" @click="newDate.status = status.key">{{ status.value }}</option>
                                </select>
                            </td>
                            <td> <input type="number" @input="event => newDate.minMen = event.target.value" style="width: 80px;" :v-model="newDate.minMen" class="form-control tbx-minMen-add" :value="newDate.minMen" /></td>
                            <td> <input type="number" @input="event => newDate.menCount = event.target.value" style="width: 80px;" :v-model="newDate.menCount" class="form-control tbx-menCount-add" :value="newDate.menCount" /></td>
                            <td> <input type="number" @input="event => newDate.minWomen = event.target.value" style="width: 80px;" :v-model="newDate.minWomen" class="form-control tbx-minWomen-add" :value="newDate.minWomen" /></td>
                            <td> <input type="number" @input="event => newDate.womenCount = event.target.value" style="width: 80px;" :v-model="newDate.womenCount" class="form-control tbx-womenCount-add" :value="newDate.womenCount" /></td>
                            <td> <input type="number" @input="event => newDate.minAges = event.target.value" style="width: 80px;" :v-model="newDate.minAges" class="form-control tbx-minAges-add" :value="newDate.minAges" /></td>
                            <td> <input type="number" @input="event => newDate.maxAges = event.target.value" style="width: 80px;" :v-model="newDate.maxAges" class="form-control tbx-maxAges-add" :value="newDate.maxAges" /></td>
                            <td> <input type="number" @input="event => newDate.price = event.target.value" style="width: 80px;" :v-model="newDate.price" class="form-control tbx-pruce-add" :value="newDate.price" /></td>
                            <td>
                                <input type="text" @input="event => newDate.videoLink = event.target.value" :v-model="newDate.videoLink" class="form-control" :value="newDate.videoLink" />
                            </td>
                            <td>
                                <button @click="addDate" class="btn btn-success">{{ $t('admin.add') }}</button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="row-date" v-for="(date, dateKey) in this.dates" :key="dateKey" :class="date.statusClass">
                            <td>{{ date.id }}</td>
                            <td> <input @input="event => date.title = event.target.value" type="text" :v-model="date.title" class="form-control" :value="date.title" /></td>
                            <td>
                                <VueDatePicker v-model="date.startsOn" />
                            </td>
                            <td>
                                <select v-model="date.status" class="form-control ddl-status" :value="date.status">
                                    <option v-for="(status, statusKey) in this.statuses" :value="status.key" :key="statusKey" @click="date.status = status.key">{{ status.value }}</option>
                                </select>
                            </td>
                            <td> <input type="number" @input="event => date.minMen = event.target.value" style="width: 80px;" :v-model="date.minMen" class="form-control tbx-minMen" :value="date.minMen" /></td>
                            <td> <input type="number" @input="event => date.menCount = event.target.value" style="width: 80px;" :v-model="date.menCount" class="form-control tbx-menCount" :value="date.menCount" /></td>
                            <td> <input type="number" @input="event => date.minWomen = event.target.value" style="width: 80px;" :v-model="date.minWomen" class="form-control tbx-minWomen" :value="date.minWomen" /></td>
                            <td> <input type="number" @input="event => date.womenCount = event.target.value" style="width: 80px;" :v-model="date.womenCount" class="form-control tbx-womenCount" :value="date.womenCount" /></td>
                            <td> <input type="number" @input="event => date.minAges = event.target.value" style="width: 80px;" :v-model="date.minAges" class="form-control tbx-minAges" :value="date.minAges" /></td>
                            <td> <input type="number" @input="event => date.maxAges = event.target.value" style="width: 80px;" :v-model="date.maxAges" class="form-control tbx-maxAges" :value="date.maxAges" /></td>
                            <td> <input type="number" @input="event => date.price = event.target.value" style="width: 80px;" :v-model="date.price" class="form-control tbx-price" :value="date.price" /></td>
                            <td>
                                <input type="text" @input="event => date.videoLink = event.target.value" :v-model="date.videoLink" class="form-control tbx-videoLink" :value="date.videoLink" />
                            </td>
                            <td>
                                <button @click="saveDate(date)" class="btn btn-success btn-save">{{ $t('admin.save') }}</button>
                                <button @click="deleteDate(date)" class="btn btn-danger btn-delete">{{ $t('admin.delete') }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
            <div class="container-fluid">
                <hgroup>
                    <h3>{{ $t('admin.users') }}</h3>
                </hgroup>
                <div>
                    <button @click="saveUsers" class="btn btn-success">{{ $t('admin.save') }}</button>
                    <button @click="deleteUsers" class="btn btn-danger">{{ $t('admin.deleteAll') }}</button>
                    <button @click="resetUsers" class="btn btn-danger">{{ $t('admin.resetAll') }}</button>
                </div>
                <table v-if="this.users" class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>{{ $t('admin.id') }}</th>
                            <th>{{ $t('admin.firstName') }}</th>
                            <th>{{ $t('admin.lastName') }}</th>
                            <th>{{ $t('admin.email') }}</th>
                            <th>{{ $t('admin.country') }}</th>
                            <th>{{ $t('admin.city') }}</th>
                            <th>{{ $t('admin.phone') }}</th>
                            <th>{{ $t('admin.active') }}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td></td>
                            <td><input @input="event => newUser.firstName = event.target.value" type="text" :v-model="newUser.firstName" class="form-control" :value="newUser.firstName" /></td>
                            <td><input @input="event => newUser.lastName = event.target.value" type="text" :v-model="newUser.lastName" class="form-control" :value="newUser.lastName" /></td>
                            <td><input @input="event => newUser.email = event.target.value" type="text" :v-model="newUser.email" class="form-control" :value="newUser.email" /></td>
                            <td><input @input="event => newUser.country = event.target.value" type="text" :v-model="newUser.country" class="form-control" :value="newUser.country" /></td>
                            <td><input @input="event => newUser.city = event.target.value" type="text" :v-model="newUser.city" class="form-control" :value="newUser.city" /></td>
                            <td><input @input="event => newUser.phone = event.target.value" type="text" :v-model="newUser.phone" class="form-control" :value="newUser.phone" /></td>
                            <td></td>
                            <td>
                                <button @click="addUser()" class="btn btn-success">{{ $t('admin.add') }}</button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="row-user" v-for="(user, userKey) in this.users" :key="userKey">
                            <td>{{ user.id }}</td>
                            <td><input @input="event => user.firstName = event.target.value" type="text" :v-model="user.firstName" class="form-control" :value="user.firstName" /></td>
                            <td><input @input="event => user.lastName = event.target.value" type="text" :v-model="user.lastName" class="form-control" :value="user.lastName" /></td>
                            <td><input @input="event => user.email = event.target.value" type="text" :v-model="user.email" class="form-control tbx-user-email" :value="user.email" /></td>
                            <td><input @input="event => user.country = event.target.value" type="text" :v-model="user.country" class="form-control" :value="user.country" /></td>
                            <td><input @input="event => user.city = event.target.value" type="text" :v-model="user.city" class="form-control" :value="user.city" /></td>
                            <td><input @input="event => user.phone = event.target.value" type="text" :v-model="user.phone" class="form-control" :value="user.phone" /></td>
                            <td><input @input="event => user.isActive = event.target.checked" type="checkbox" :checked="user.isActive" class="form-check" /></td>
                            <td>
                                <button @click="saveUser(user)" class="btn btn-success">{{ $t('admin.save') }}</button>
                                <button @click="deleteUser(user)" class="btn btn-danger">{{ $t('admin.delete') }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import { fetchWrapper } from '@/helpers';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import moment from 'moment';

    export default {
        data() {
            return {
                moment: moment,
                ethnicities: [],
                dateTypes: [],
                dates: [],
                users: [],
                statuses: [],
                newDate: {
                    title: '',
                    startsOn: new Date(),
                    status: 1,
                    minMen: 0,
                    menCount: 0,
                    minWomen: 0,
                    womenCount: 0,
                    minAges: 0,
                    maxAges: 0,
                    price: 0,
                    description: '',
                    shortDescription: '',
                    isActive: true
                },
                newUser: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: 'Hakerman91!',
                    country: '',
                    city: '',
                    phone: '',
                    gender: 1,
                    ethnicity: 1,
                    isActive: true
                }
            };
        },
        async mounted() {
            this.ethnicities = await fetchWrapper.get('Options/GetEthnicities');
            this.dateTypes = await fetchWrapper.get('Options/GetDateTypes');
            this.dates = await fetchWrapper.get('Dates/GetAll');
            for (let i = 0; i < this.dates.length; i++) {
                let currDate = this.dates[i];
                if (currDate.status == 2) {
                    currDate.statusClass = 'status-approved';
                } else if (currDate.status == 3) {
                    currDate.statusClass = 'status-started';
                } else if (currDate.status == 4) {
                    currDate.statusClass = 'status-finished';
                } else if (currDate.status == 5) {
                    currDate.statusClass = 'status-revealed';
                } else {
                    currDate.statusClass = 'status-not-approved';
                }
            }
            this.users = await fetchWrapper.get('User/GetAll');
            this.statuses = await fetchWrapper.get('Options/GetDateStatuses');
        },
        methods: {
            async deleteSpots() {
                if (confirm('are you sure?')) {
                    await fetchWrapper.delete('Admin/DeleteAllSpots');
                    this.dates = await fetchWrapper.get('Dates/GetAll');
                }
            },
            async deleteOrders() {
                if (confirm('are you sure?')) {
                    await fetchWrapper.delete('Admin/DeleteAllOrders');
                    this.dates = await fetchWrapper.get('Dates/GetAll');
                }
            },
            async resetDates() {
                if (confirm('are you sure?')) {
                    await fetchWrapper.delete('Admin/ResetAllDates');
                    this.dates = await fetchWrapper.get('Dates/GetAll');
                }
            },
            async addDate() {
                this.newDate.orders = [];
                this.newDate.attendees = [];
                this.newDate.dateType = 1;
                let result = await fetchWrapper.post('Admin/AddDate', this.newDate);
                this.dates.push(result);
            },
            async saveDate(date) {
                date.orders = [];
                date.attendees = [];
                await fetchWrapper.put('Admin/SaveDate', date);
            },
            async saveDates() {
                for (let i = 0; i < this.dates.length; i++) {
                    this.dates[i].orders = [];
                    this.dates[i].attendees = [];
                }
                await fetchWrapper.put('Admin/SaveAllDates', this.dates);
            },
            async deleteDate(date) {
                await fetchWrapper.delete('Admin/DeleteDate?id=' + date.id);
                let index = this.dates.indexOf(date);
                this.dates.splice(index, 1);
            },
            async deleteDates() {
                if (confirm('are you sure?')) {
                    await fetchWrapper.delete('Admin/DeleteAllDates');
                    this.dates = [];
                }
            },
            async addUser() {
                this.newUser.orders = [];
                this.newUser.choices = [];
                this.newUser.pictures = [];
                var result = await fetchWrapper.post('Admin/AddUser', this.newUser);
                this.users.push(result);
            },
            async saveUser(user) {
                user.orders = [];
                user.choices = [];
                user.pictures = [];
                await fetchWrapper.put('Admin/SaveUser', user);
            },
            async saveUsers() {
                for (let i = 0; i < this.users.length; i++) {
                    this.users[i].orders = [];
                    this.users[i].choices = [];
                    this.users[i].pictures = [];
                }
                await fetchWrapper.put('Admin/SaveAllUsers', this.users);
            },
            async deleteUser(user) {
                await fetchWrapper.delete('User/Delete?userId=' + user.id);
                let index = this.users.indexOf(user);
                this.users.splice(index, 1);
            },
            async deleteUsers() {
                if (confirm('are you sure?')) {
                    await fetchWrapper.delete('Admin/DeleteAllUsers');
                    this.users = [];
                }
            },
            async resetUsers() {
                if (confirm('are you sure?')) {
                    await fetchWrapper.put('Admin/ResetAllUsers');
                    this.users = [];
                }
            }
        },
        components: { VueDatePicker }
    }
</script>

<style scoped>
    .status-not-approved {
        background-color: white;
    }

    .status-approved {
        background-color: lightyellow;
    }

    .status-started {
        background-color: organge;
    }

    .status-finished {
        background-color: lightblue;
    }

    .status-revealed {
        background-color: lightgreen;
    }

    .admin-panel {
        padding: 2rem 0;
    }

    .admin-header {
        margin-bottom: 2rem;
    }

    .nav-tabs {
        margin-bottom: 2rem;
    }

    .tab-content {
        padding: 2rem;
        background: #fff;
        border: 1px solid #dee2e6;
        border-top: none;
        border-radius: 0 0 .25rem .25rem;
    }

    .action-buttons {
        margin: 1rem 0;
        gap: 0.5rem;
        display: flex;
        flex-wrap: wrap;
    }

    .table {
        margin-top: 1rem;
        vertical-align: middle;
    }

    .table input {
        min-width: 120px;
    }

    .table td {
        padding: 0.75rem;
    }

    .btn-group-actions {
        display: flex;
        gap: 0.5rem;
    }

    .status-not-approved { background-color: #fff; }
    .status-approved { background-color: #fff3cd; }
    .status-started { background-color: #ffe5d0; }
    .status-finished { background-color: #cfe2ff; }
    .status-revealed { background-color: #d1e7dd; }

    /* Responsive adjustments */
    @media (max-width: 992px) {
        .table-responsive {
            overflow-x: auto;
        }
        
        .action-buttons {
            justify-content: center;
        }
    }

    /* Additional media query for smaller screens */
    @media (max-width: 576px) {
        .action-buttons {
            flex-direction: column;
        }
    }
</style>