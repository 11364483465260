import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAUl4wVd_Spk7rphPDLxRGxoKjFW09jUYw",
  authDomain: "takerman-dating.firebaseapp.com",
  projectId: "takerman-dating",
  storageBucket: "takerman-dating.firebasestorage.app",
  messagingSenderId: "731201943045",
  appId: "1:731201943045:web:d5b0f6e43881ac528a4e6c",
  measurementId: "G-MS63X67Q1J"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);