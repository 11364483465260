<template>
<div v-if="!loading && !error && post" class="card h-100">
    <div class="card-body">
        <h5 @click="handleTitleClick" class="card-title">{{ post.title }}</h5>
        <p class="card-text" v-html="post.content.substring(0, 80)"></p>
        <small class="text-muted">{{ moment(new Date(post.datePublished)).format("DD-MMM-YYYY") }}</small>
        <div v-if="loading" class="spinner-border" role="status">
            <span class="sr-only">{{ $t('loading') }}</span>
        </div>
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ $t('error') }}
        </div>
    </div>
</div>
</template>
<script lang="js">
import blogService from '@/services/blogService';
import moment from 'moment';

export default {
    data() {
        return {
            moment: moment,
            loading: false,
            error: ''
        };
    },
    props: {
        post: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleTitleClick() {
            this.$router.push('/blog/' + this.post.id);
            console.log('Title clicked:', this.post.title);
        }
    }
}
</script>
<style scoped>
.container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

h1 {
    font-family: 'Arial', sans-serif;
    font-size: 3rem;
    color: #333;
}

.card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.card:hover {
    transform: translateY(-10px);
}

.card-title {
    font-family: 'Georgia', serif;
    font-size: 1.5rem;
    color: #007bff;
    cursor: pointer;
}

.card-text {
    font-family: 'Verdana', sans-serif;
    font-size: 1rem;
    color: #555;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
    color: #007bff;
}

.alert {
    font-family: 'Arial', sans-serif;
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .card {
        margin-bottom: 20px;
    }
}
</style>