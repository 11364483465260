import { fetchWrapper } from '@/helpers';

export default class BlogService {

    static async getAllBlogPosts() {
        try {
            return await fetchWrapper.get('Blog/GetAllBlogposts');
        } catch (error) {
            throw new Error('Failed to fetch blog posts');
        }
    }

    static async getBlogPostById(id) {
        try {
            return await fetchWrapper.get(`/Blog/GetBlogpost?id=${id}`);
        } catch (error) {
            throw new Error(`Failed to fetch blog post with id ${id}`);
        }
    }

    static async deleteBlogPost(id) {
        try {
            return await fetchWrapper.delete(`/Blog/DeleteBlogpost?id=${id}`);
        } catch (error) {
            throw new Error(`Failed to delete blog post with id ${id}`);
        }
    }

    static async updateBlogPost(id, blogPost) {
        try {
            return await fetchWrapper.put(`/Blog/UpdateBlogpost?id=${id}`, blogPost);
        } catch (error) {
            throw new Error(`Failed to update blog post with id ${id}`);
        }
    }

    static async createBlogPost(blogPost) {
        try {
            return await fetchWrapper.post('/Blog/CreateBlogpost', blogPost);
        } catch (error) {
            throw new Error('Failed to create blog post');
        }
    }
}