<template>
	<breadcrumbs :paths="this.breadcrumbs" />
	<loader v-if="this.loading" />
	<div v-else-if="this.date" class="container-fluid pb-5 text-center">
		<div class="row px-xl-5">
			<div class="col-lg-5 mb-30">

				<div id="datePictures" class="carousel slide" data-bs-ride="carousel">
					<div class="carousel-indicators">
						<button v-for="(picture, pictureKey) in this.date.pictures" :key="pictureKey" type="button" data-bs-target="#datePictures" class="active" :data-bs-slide-to="pictureKey" :aria-label="$t('date.slide') + pictureKey"></button>
					</div>
					<div class="carousel-inner">
						<div v-for="(picture, pictureKey) in this.date.pictures" :key="pictureKey" :class="'carousel-item ' + (pictureKey == 0 ? 'active' : '')">
						<img :src="picture.url" class="d-block w-100" :alt="pictureKey">
						</div>
					</div>
					<button class="carousel-control-prev" type="button" data-bs-target="#datePictures" data-bs-slide="prev">
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="visually-hidden">{{ $t('date.previous') }}</span>
					</button>
					<button class="carousel-control-next" type="button" data-bs-target="#datePictures" data-bs-slide="next">
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="visually-hidden">{{ $t('date.next') }}</span>
					</button>
				</div>
			</div>
			<div class="col-lg-7 h-auto mb-30">
				<div class="h-100 bg-light p-30">
					<hgroup>
						<h3>{{ this.date.dateType }} {{ this.date.title }}</h3>
					</hgroup>
					<br />
					<p class="mb-4">{{ $t("date.description") }}</p>
					<p v-if="this.date" class="mb-4">{{ $t("date.description") }}</p>
					<table class="table">
						<tbody>
							<tr>
								<td>
									<strong><i class="bi bi-person-standing"></i>{{ $t("dates.card.menCount") }}</strong>
									{{ this.date.menCount }}/{{ this.date.minMen }}
								</td>
								<td>
									<strong><i class="bi bi-person-standing-dress"></i>{{ $t("dates.card.womenCount") }}</strong>
									{{ this.date.womenCount }}/{{ this.date.minWomen }}
								</td>
							</tr>
							<tr>
								<td>
									<strong>{{ $t("dates.card.minAges") }}</strong> {{ this.date.minAges }}
								</td>
								<td>
									<strong>{{ $t("dates.card.maxAges") }}</strong> {{ this.date.maxAges }}
								</td>
							</tr>
							<tr>
								<td colspan="2" style="font-size: x-large">
									<strong><i class="bi bi-calendar-event"></i>
										{{ $t("dates.card.startsOn") }}</strong><br />{{
											this.date.startsOn
												? moment(this.date.startsOn).format("DD MMM, HH:mm")
													: $t("dates.card.noDate")
										}}
								</td>
							</tr>
						</tbody>
					</table>
					<div>
						<h3 class="font-weight-semi-bold">
							<i class="bi bi-tag"></i>{{ $t("common.currencySign")
							}}{{ this.date.price }}
						</h3>
						<p v-if="this.date.status === 'NotApproved'">
							<a
							   v-if="this.isSpotSaved"
							   @click="unsaveSpot(this.date)"
							   class="btn btn-danger">{{ $t("date.unsaveSpot") }}</a>
							<a v-else @click="saveSpot(this.date)" class="btn btn-primary">{{ $t("date.saveSpot") }}</a>
						</p>
						<div v-if="this.date.status === 'Approved' && this.startTime">
							<p>
								{{ $t("date.startsIn") }} <br />
								<strong style="font-size: x-large">{{ this.startTime }}</strong>
							</p>
							<div v-if="this.isBought">
								<a
								   v-if="moment(this.date.startsOn).add(-15, 'minutes') < moment()"
								   :href="this.date.videoLink"
								   class="btn btn-success btn-lg"
								   target="_blank">{{ $t("date.joinMeeting") }}</a>
								<p v-else>
									{{ $t("date.alreadyBought") }} <br />
									{{ $t("date.joinBeforeTimerEnds") }}
								</p>
							</div>
							<div v-else>
								<PayButton
										   v-if="this.date.price > 0"
										   :date-id="this.date.id"
										   :on-approve="onApprove"
										   :on-error="onError"
										   class="pay-button text-center">{{ $t("date.buy") }}</PayButton>
								<br />
								<div
									 v-if="this.paymentStatus === 'success'"
									 class="alert alert-success"
									 role="alert">
									<span
										  class="glyphicon glyphicon-exclamation-sign"
										  aria-hidden="true"></span>
									<span class="sr-only"></span> {{ $t("date.purchaseSuccess") }} <router-link to="orders">{{ $t("date.myDates") }}</router-link>
								</div>
								<div
									 v-else-if="this.paymentStatus === 'failed'"
									 class="alert alert-danger"
									 role="alert">
									<span
										  class="glyphicon glyphicon-exclamation-sign"
										  aria-hidden="true"></span>
									<span class="sr-only"></span> {{ $t("date.purchaseError") }}
								</div>
							</div>
						</div>
						<div v-if="this.date.status === 'Started'">
							<strong>{{ $t("date.started") }}</strong><br />
							<div v-if="this.isBought">
								<a
								   :href="this.date.videoLink"
								   class="btn btn-success btn-lg"
								   target="_blank">{{ $t("date.joinMeeting") }}</a>
							</div>
							<div v-else>
								{{ $t("date.notBought") }}
							</div>
						</div>
						<div v-if="this.date.status === 'Finished'">
							<p>{{ $t("date.finished") }}</p>
							<p>{{ $t("date.voteBelow") }}</p>
							<p>
								{{ $t("date.resultsRevealedIn") }}
								<span style="font-size: x-large; font-weight: bold">{{
									this.revealTime
								}}</span>
								{{ $t("date.hours") }}
							</p>
						</div>
						<div v-if="this.date.status === 'ResultsRevealed'">
							{{ $t("date.resultsRevealed") }}<br />
							{{ $t("date.chatAndViewProfiles") }}
						</div>
					</div>
					<br />
					<div class="d-flex pt-2">
						<strong class="text-dark mr-2">{{ $t("social.shareOn") }}</strong>
						<div class="d-inline-flex">
							<a class="text-dark px-2" @click="shareOnFacebook" href="#">
								<i class="fab fa-facebook-f"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			 v-if="
				this.date &&
				this.date.id != null &&
				(this.date.status == 'Started' ||
					this.date.status == 'Finished' ||
					this.date.status == 'ResultsRevealed')
			"
			 class="row px-xl-5">
			<div class="col">
				<div class="bg-light p-30">
					<Choices :date-id="this.date.id" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import moment from 'moment';
	import { useAuthStore } from '@/stores';
	import PayButton from '../components/PayButton.vue';
	import { router } from '@/helpers';
	import { useRoute } from 'vue-router';
	import { fetchWrapper } from '@/helpers';
	import breadcrumbs from '../components/Breadcrumbs.vue';
	import loader from '../components/Loader.vue';
	import heading from '../components/Heading.vue';
	import Choices from '../components/Choices.vue';

	export default {
		data() {
			return {
				id: 0,
				userId: null,
				date: null,
				startTime: null,
				revealTime: null,
				loading: false,
				moment: moment,
				paymentStatus: '',
				isBought: false,
				isSpotSaved: false,
				interval: null,
				breadcrumbs: [
					{
						name: '/',
						title: 'Начало'
					},
					{
						name: '#',
						title: 'Среща'
					}
				]
			};
		},
		computed: {
			path() {
				var route = useRoute();
				return route.path;
			},
			isPast() {
				return new Date(this.date?.startsOn) < new Date();
			}
		},
		async mounted() {
			this.loading = true;
			let queryString = window.location.search;
			let urlParams = new URLSearchParams(queryString);

			const authStore = useAuthStore();
			if (authStore.user)
				this.userId = authStore.user.id;
			if (urlParams.has('id')) {
				this.id = urlParams.get('id');
				this.interval = setInterval(async () => {
					this.date = await fetchWrapper.get('Dates/GetDate?id=' + this.id);
				}, 1000);
				this.isBought = await (await fetch('Dates/IsBought?dateId=' + this.id + (this.userId ? '&userId=' + this.userId : ''))).json();
				this.isSpotSaved = await (await fetch('Dates/IsSpotSaved?dateId=' + this.id + (this.userId ? '&userId=' + this.userId : ''))).json();
			}
			this.loading = false;
		},
		unmounted() {
			if (this.interval)
				clearInterval(this.interval);
		},
		methods: {
			shareOnFacebook() {
				const url = encodeURIComponent(window.location.href);
				const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
				window.open(shareUrl, 'FacebookShare', 'width=626,height=436');
			},
			async onApprove(e, o) {
				this.paymentStatus = 'success';

				const data = {
					dateId: this.date?.id,
					userId: this.userId,
					paymentId: e.paymentID,
					payerId: e.payerID,
					orderId: e.orderID,
					paymentSource: e.paymentSource
				}

				let order = await fetchWrapper.post('Order/Create', data);

				this.date = await fetchWrapper.get('Dates/GetDate?id=' + order.dateId);

				const payButton = document.getElementsByClassName('pay-button');
				for (let i = 0; i < payButton.length; i++) {
					const element = payButton[i];
					element.style.display = 'none';
				}
			},
			onError(e) {
				this.paymentStatus = 'fail';
			},
			async saveSpot(date) {
				const authStore = useAuthStore();
				if (!authStore.user) {
					router.push('/login?returnUrl=/date?id=' + this.date?.id);
				} else {
					await fetchWrapper.get('Dates/SaveSpot' + (authStore.user == null ? '' : '?userId=' + authStore.user.id + '&dateId=' + this.date.id))
						.then((result) => {
							this.date.status = result.status;
							this.date.menCount = result.menCount;
							this.date.womenCount = result.womenCount;
							this.emitter.emit('addToSpotCount', { 'eventContent': 1 });
							this.isSpotSaved = true;
						});
				}
			},
			async unsaveSpot(date) {
				const authStore = useAuthStore();
				if (!authStore.user) {
					router.push('/login?returnUrl=/date?id=' + this.date?.id);
				} else {
					await fetchWrapper.get('Dates/UnsaveSpot' + (authStore.user == null ? '' : '?userId=' + authStore.user.id + '&dateId=' + this.date.id))
						.then((result) => {
							this.date.status = result.status;
							this.date.menCount = result.menCount;
							this.date.womenCount = result.womenCount;
							this.emitter.emit('addToSpotCount', { 'eventContent': -1 });
							this.isSpotSaved = false;
						});
				}
			}
		},
		components: {
			loader,
			breadcrumbs,
			heading,
			Choices,
			PayButton
		}
	}
</script>

<style scoped>
.date-container {
	background-color: #f8f9fa;
	border-radius: 15px;
	box-shadow: 0 0 15px rgba(0,0,0,0.1);
	margin-top: 2rem;
}

.carousel {
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.carousel-item img {
	height: 400px;
	object-fit: cover;
}

.date-details {
	padding: 2rem;
	background: white;
	border-radius: 15px;
	margin: 1rem 0;
}

.price-tag {
	color: #28a745;
	font-size: 1.5rem;
	font-weight: bold;
}

.date-stats {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	margin: 1.5rem 0;
}

.date-stat-item {
	flex: 1;
	min-width: 150px;
	padding: 1rem;
	background: #f8f9fa;
	border-radius: 10px;
	text-align: center;
}

.btn-custom {
	padding: 0.8rem 2rem;
	border-radius: 25px;
	font-weight: 600;
	transition: all 0.3s ease;
}

.social-share {
	padding: 1rem;
	border-top: 1px solid #dee2e6;
	margin-top: 2rem;
}

.social-share a {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: #f8f9fa;
	transition: all 0.3s ease;
}

.social-share a:hover {
	background: #e9ecef;
	transform: translateY(-2px);
}

.social-share a {
	color: #007bff;
	text-decoration: none;
	margin: 0 5px;
}

.social-share a:focus, .social-share a:hover {
	color: #0056b3;
	text-decoration: underline;
}
.social-share {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
.social-share a {
	margin: 0 10px;
	font-size: 1.5em;
	color: #333;
}
.social-share a:hover {
	color: #007bff;
	text-decoration: underline;
}
</style>