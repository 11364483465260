<template>
    <div class="container">
        <!-- Profile Picture Section -->
        <div class="row justify-content-center mb-4">
            <div class="col-md-4 text-center">
                <img :src="this.avatar" class="rounded-circle shadow-sm mb-3" width="150" height="150" alt="Profile Picture" />
                <div>
                    <button class="btn btn-outline-danger" @click="this.setDefaultAvatar">
                        <i class="bi bi-x-circle me-2"></i>{{ $t('gallery.removeProfilePicture') }}
                    </button>
                </div>
            </div>
        </div>

        <!-- Upload Form Section -->
        <div class="row justify-content-center mb-4">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <form id="uploadForm" @submit="this.upload" method="post" enctype="multipart/form-data" class="row g-3 align-items-center">
                            <div class="col-auto">
                                <input id="fileUpload" type="file" multiple class="form-control" />
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-primary">
                                    <i class="bi bi-upload me-2"></i>{{ $t('gallery.upload') }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Loading Spinner -->
        <div v-if="this.loading" class="row justify-content-center">
            <div class="col-md-4 text-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">{{ $t('gallery.loading') }}</span>
                </div>
            </div>
        </div>

        <!-- Pictures Grid -->
        <div class="row row-cols-1 row-cols-md-3 g-4">
            <div class="col" v-for="(picture, pictureKey) in pictures" :key="pictureKey">
                <div class="card h-100">
                    <img :src="picture.url" class="card-img-top" :title="$t('gallery.uploadedOn') + picture.uploadOn"
                        style="height: 200px; object-fit: cover;" />
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <button class="btn btn-sm btn-danger" @click="remove(picture.id)">
                                <i class="bi bi-trash me-1"></i>{{ $t('gallery.delete') }}
                            </button>
                            <button class="btn btn-sm btn-primary" @click="setAvatar(picture.url)">
                                <i class="bi bi-person-circle me-1"></i>{{ $t('gallery.setProfilePicture') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchWrapper } from '@/helpers';
import { useAuthStore } from '@/stores';
import Avatar from '../components/Avatar.vue';
export default {
    data() {
        return {
            loading: false,
            userId: null,
            avatar: null,
            pictures: []
        };
    },
    async mounted() {
        const authStore = useAuthStore();
        this.userId = authStore.user.id;
        this.pictures = await this.getPictures();
        let result = await fetch('Cdn/GetAvatar?userId=' + this.userId);
        this.avatar = await result.text();
    },
    components: {
        Avatar
    },
    methods: {
        async getPictures() {
            this.loading = true;
            let result = await fetchWrapper.get('Cdn/GetUserPictures?userId=' + this.userId);
            this.loading = false;
            return result;
        },
        async upload(e) {
            this.loading = true;
            e.preventDefault();

            const formData = new FormData();
            const input = document.getElementById('fileUpload').files;
            for (var x = 0; x < input.length; x++) {
                formData.append("files", input[x]);
            }

            await fetch('Cdn/UploadUserPictures?userId=' + this.userId, {
                method: 'POST',
                body: formData
            }).then(response => {
                this.loading = false;
                for (let i = 0; i < response.length; i++) {
                    const picture = response[i];
                    this.pictures[this.pictures.length] = picture;
                }
                location.reload();
            });
        },
        async setAvatar(url) {
            this.avatar = url;
            await fetch('Cdn/SetAvatar?userId=' + this.userId + '&url=' + url);
        },
        async setDefaultAvatar() {
            let result = await fetch('Cdn/SetDefaultAvatar?userId=' + this.userId);
            this.avatar = await result.text();
        },
        async remove(id) {
            await fetchWrapper.delete('Cdn/DeletePicture?id=' + id)
                .then(async (response) => {
                    for (let i = 0; i < this.pictures.length; i++) {
                        const picture = this.pictures[i];
                        if (picture.id === id) {
                            if (this.avatar == picture.url) {
                                await this.setDefaultAvatar();
                            }
                            this.pictures.splice(i, 1);
                        }
                    }
                });
        }
    }
};
</script>
<style scoped>
.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
}
.img {
    border-radius: 50%;
    margin: 10px;
}
.btn {
    margin: 5px;
}
#uploadForm {
    margin-top: 20px;
}
.picture-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.picture-item {
    position: relative;
}
.picture-item img {
    border-radius: 10px;
}
.picture-item .btn {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>